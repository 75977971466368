import React from 'react';

import { useTranslation } from 'next-i18next';

import TextHeader, { TAG, SIZE, WEIGHT } from '@components/TextHeader';
import Button, { BUTTON_THEME } from '@components/Button';
import Link from '@components/Link';
import { Container, TopSection, Wrapper, Messages, Message, Description } from '@components/News/styles';

interface News {
  news: NewsItem[];
}

function News({ news }: News) {
  const { t } = useTranslation('news');

  if (!news?.length) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <TopSection>
          <TextHeader tag={TAG.H1} size={SIZE.LG} weight={WEIGHT.NORMAL}>
            {t('News')}
          </TextHeader>
          <Button buttonTheme={BUTTON_THEME.TEXT}>{t('Show More')}</Button>
        </TopSection>
        <Messages>
          {news.map((item: NewsItem) => (
            <Message key={item.id}>
              <img src={item.image.desktop} alt="" />
              <TextHeader tag={TAG.H2} weight={WEIGHT.BOLD} size={SIZE.MD}>
                {item.title}
              </TextHeader>
              <Description dangerouslySetInnerHTML={{ __html: item.content }} />
              <Link href={item.orgNewsUrl}>{t('Read More')}</Link>
            </Message>
          ))}
        </Messages>
      </Wrapper>
    </Container>
  );
}

export default News;
