import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Form = styled.form`
  display: flex;
  max-width: fit-content;
  margin: 0 auto;
  padding: 60px 0 90px;
  text-align: left;
  position: relative;

  > div {
    width: 416px;
  }

  .reset-button {
    position: absolute;
    z-index: 10;
    bottom: 40px;
    right: 0;
  }

  @media screen and (max-width: 1350px) {
    .reset-button {
      right: 15px;
      bottom: 20px;
    }
  }

  @media screen and ${breakpoint.lg} {
    display: block;
    max-width: unset;
    margin: 0 100px;

    > div {
      width: 100%;
    }
  }

  @media screen and ${breakpoint.md} {
    margin: 0;
    padding: 30px 0 50px;

    .simple-dropdown__indicators {
      display: none;
    }

    > div {
      width: 100%;
    }
  }
`;

export const Input = styled.input`
  width: 416px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  border: none;
  padding: 0 24px;
  color: #00405b;
  font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};
  font-size: ${fontSize(18)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.27px;

  &::placeholder {
    color: #00405b;
    font-weight: 300;
  }

  &:focus {
    outline: none;
  }

  @media screen and ${breakpoint.lg} {
    width: 100%;
    margin-top: 20px;
    height: 100px;
  }
`;

export const ClearButton = styled.button`
  border: none;
  background-color: inherit;
  color: #002330;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ClearButtonIcon = styled.div`
  margin-right: 10px;
`;
