import styled from '@emotion/styled';
import { ContentWrapper, fontSize } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

import BackgroundImage from '@assets/images/news-section-background.svg';

export const Container = styled.section`
  position: relative;
  width: 100%;
  padding: 72px 0;
  background: url(${BackgroundImage.src}) no-repeat bottom 0 left 0 / 285px;

  @media (max-width: 1890px) {
    background-position-x: -50px;
  }

  @media (max-width: 1790px) {
    background-position-x: -100px;
  }

  @media (max-width: 1690px) {
    background-image: none;
  }

  @media screen and ${breakpoint.lg} {
    padding: 32px 0;
  }
`;

export const BorderImage = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: -310px;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  h1 {
    font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
  }

  @media screen and ${breakpoint.lg} {
    margin-bottom: 15px;
    h1 {
      font-size: ${fontSize(32)};
    }

    button {
      font-size: ${fontSize(12)};
    }
  }
`;

export const Wrapper = styled(ContentWrapper)`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Messages = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 33px;

  @media screen and ${breakpoint.lg} {
    display: block;

    div:first-of-type {
      a {
        margin: 30px 0;
      }
    }
  }

  @media screen and (max-width: 520px) {
    h2 {
      margin-top: 15px;
    }
  }
`;

export const Message = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 280px;
    display: block;
    object-fit: contain;
  }

  h2 {
    font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
    margin: 30px 0;
  }

  a {
    margin-top: 50px;
    display: block;
    text-align: right;
  }

  @media screen and ${breakpoint.lg} {
    width: 100%;
    a {
      margin-top: 30px;
    }
  }
`;

export const TemporaryImage = styled.div`
  width: 100%;
  height: 280px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
`;

export const Description = styled.div`
  font-size: ${fontSize(16)};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.24px;
  color: #00405b;

  a {
    margin-left: 0;
    margin-top: 0;
  }
`;
