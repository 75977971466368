/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import routes from '@utils/routes';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Button from '@components/Button';
import OfferBox from '@components/OfferBox';
import Preloader from '@components/Preloader';
import { isEmpty } from 'lodash';
import { Container, Wrapper, OffersHeader, Offers as StyledOffers, ButtonWithMargin } from '@components/Offers/styles';

import OffersFilters from '@components/OffersFilters';

interface OffersProps {
  offers?: OfferBox[];
  offersCount: number;
  internships?: OfferBox[];
  hideFilters?: boolean;
}

function Offers({ offers, offersCount, internships, hideFilters }: OffersProps) {
  const { t } = useTranslation('offers');
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  async function onSubmit(query) {
    setIsLoading(true);
    const { offer } = query;

    const params = [];
    Object.keys(offer).forEach((key) => {
      if (offer[key]) {
        params.push(`${key}=${offer[key]}`);
      }
    });

    await router.push(`${routes.offers}?${params.join('&')}`);
  }

  if (isEmpty(offers) && isEmpty(internships)) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        {isLoading ? <Preloader overlay /> : null}
        {!hideFilters && <OffersFilters onSubmit={onSubmit} offersCount={offersCount} />}
        {internships && internships.length > 0 && (
          <>
            <OffersHeader>{t('Promowane oferty wakacyjne')}</OffersHeader>
            <StyledOffers>
              {internships.map((internship: OfferBox) => (
                <OfferBox key={`inrternship-box_${internship.id}`} offer={internship} />
              ))}
            </StyledOffers>
            <ButtonWithMargin>
              <Button href={`${routes.offers}?practiceType=holiday_internship`}>{t('Show More')}</Button>
            </ButtonWithMargin>
          </>
        )}
        {offers && offers.length > 0 && (
          <>
            <OffersHeader>{t('Promowane staże i praktyki')}</OffersHeader>
            <StyledOffers>
              {offers.map((offer: OfferBox) => (
                <OfferBox key={`offer-box_${offer.id}`} offer={offer} />
              ))}
            </StyledOffers>
            <Button href={`${routes.offers}?practiceType=internship,practice`}>{t('Show More')}</Button>
          </>
        )}
      </Wrapper>
    </Container>
  );
}

Offers.defaultProps = {
  offers: null,
  internships: null,
  hideFilters: false,
};

export default Offers;
