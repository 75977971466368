import React, { useEffect, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import AsyncDropdown from '@components/Form/Dropdown/AsyncDropdown';
import { API, APIRoutes } from '@utils/api';
import { isObjectEmpty } from '@utils/dataHelpers';

import { Form, Input, ClearButton, ClearButtonIcon } from '@components/EmployersFilers/styles';
import resetBtn from '@assets/images/icons-close.svg';

interface EmployersFiltersProps {
  onSubmit: (data: Record<string, unknown>) => void;
  resetable?: boolean;
}

function EmployersFilters({ onSubmit, resetable }: EmployersFiltersProps) {
  const { t } = useTranslation('employers');
  const {
    query: { fieldsOfExpertiseId, name },
  } = useRouter();
  const [showResetButton, setShowResetButton] = useState(isObjectEmpty({ fieldsOfExpertiseId, name }));

  const fetchFieldsOfExpertise = useCallback(async (): Promise<OptionType[]> => {
    const {
      data: { fieldsOfExpertises },
    } = await API.get(APIRoutes.fieldsOfExpertises);
    return [{ id: '', name: t('All') }, ...fieldsOfExpertises];
  }, [t]);

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      employer: {
        ...(name ? { name } : {}),
        ...(fieldsOfExpertiseId ? { fieldsOfExpertiseId: parseInt(fieldsOfExpertiseId.toString(), 10) } : {}),
      },
    },
  });

  const resetFilters = useCallback(() => {
    setValue('employer.fieldsOfExpertiseId', null);
    setValue('employer.name', '');
  }, [setValue]);

  useEffect(() => {
    const subscription = watch(({ employer }) => {
      setShowResetButton(isObjectEmpty(employer));
      return handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="employer.fieldsOfExpertiseId"
        render={({ field: { name: inputName, onChange, value } }) => (
          <AsyncDropdown
            loadOptions={fetchFieldsOfExpertise}
            id="fieldsOfExpertise"
            name={inputName}
            onChange={onChange}
            value={value}
            label={t('Industry')}
            type="standalone"
            placeholder={t('Select')}
            withNullValue
          />
        )}
      />
      <Controller
        control={control}
        name="employer.name"
        render={({ field: { name: inputName, onChange, value } }) => {
          return (
            <Input
              type="search"
              onChange={onChange}
              value={value || ''}
              name={inputName}
              placeholder={t('Search employer...')}
            />
          );
        }}
      />
      {resetable && showResetButton ? (
        <ClearButton type="button" onClick={resetFilters} className="reset-button">
          <ClearButtonIcon>
            <Image src={resetBtn} />
          </ClearButtonIcon>
          {t('Clear filters')}
        </ClearButton>
      ) : null}
    </Form>
  );
}

EmployersFilters.defaultProps = {
  resetable: false,
};

export default EmployersFilters;
